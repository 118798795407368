import React, { Component } from 'react';
import Header from '../components/Header';
import BigBanner from '../components/BigBanner';
import ListCatalogPopular from '../components/ListCatalog-Popular';
import ListCatalogOngoing from '../components/ListCatalog-Ongoing';
import ListCatalogAnons from '../components/ListCatalog-Anons';
import ListCatalogMovie from '../components/ListCatalog-Movie';
import ListCatalogCompilation from '../components/ListCatalog-Compilation';
import ListCatalogLates from '../components/ListCatalog-Lates';
import ListCatalog2024 from '../components/ListCatalog-Top2024';
import Footer from '../components/Footer';
import ListCatalog4K from '../components/ListCatalog-4k';
import ListCatalogTop10 from '../components/ListCatalogTop10';
import { Helmet } from "react-helmet";

export default class Welcome extends Component {
    render() {
      return (
          <div className="welcome">
              <Helmet>
                <title>AniPlay | Смотреть аниме в 4К!</title>
              </Helmet>
              <Header />
              <BigBanner />
              <div className='container'>
                <ListCatalogPopular />
                <ListCatalogOngoing />
                <ListCatalogAnons />
                <ListCatalogTop10/>
                <ListCatalogLates />
                <ListCatalogCompilation />
                <ListCatalog2024 />
                <ListCatalogMovie />
              </div>
              <div style={{height: "70px"}}></div>
              <Footer />
          </div>
      )
    }
}
