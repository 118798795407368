import React, { useState, useEffect } from 'react'; // Импортируем useState и React
import { useNavigate } from 'react-router-dom'; // Импортируем useNavigate
import axios from 'axios'; // Импортируем axios

export default function Auth() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
      const token = localStorage.getItem('token');
      if (token) {
          navigate('/welcome');
      }
  }, [navigate]);

  // Регулярное выражение для проверки email
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

  const showMessage = (messageType, message) => {
    if (messageType === 'error') setErrorMessage(message);
    else setSuccessMessage(message);
    setIsVisible(true);
    setTimeout(() => setIsVisible(false), 5000);
  };

  // Функция проверки email
  const isValidEmail = (email) => {
    return emailRegex.test(email);
  };

  const handleEmailSubmit = async () => {
    // Проверка на пустое поле
    if (!email) {
      showMessage('error', 'Введите ваш email');
      return;
    }

    // Проверка на корректность email
    if (!isValidEmail(email)) {
      showMessage('error', 'Пожалуйста, введите корректный email');
      return;
    }

    // Отправка запроса на сервер
    try {
      const response = await axios.post('https://api.aniplay.fun/send-2fa-code', { email });
      if (response.data.message) {
        setIsCodeSent(true);
        showMessage('success', 'Код отправлен на ваш email');
      }
    } catch (error) {
      showMessage('error', error.response?.data?.error || 'Ошибка при отправке кода');
    }
  };

  const handleCodeSubmit = async () => {
    if (!code) {
      showMessage('error', 'Введите полученный код');
      return;
    }

    try {
      const response = await axios.post('https://api.aniplay.fun/verify-2fa-code', { email, code });
      showMessage('success', 'Вход выполнен успешно!');
      localStorage.setItem('token', response.data.token);  // Сохраняем токен в localStorage
      localStorage.setItem('userId', response.data.userId);
      navigate('/welcome');
    } catch (error) {
      showMessage('error', error.response?.data?.error || 'Ошибка при проверке кода');
    }
  };

  // Функция для возврата к форме ввода email
  const handleBack = () => {
    setIsCodeSent(false);
    setCode('');  // Сбросить код, чтобы начать с пустого поля
  };

  return (
    <div className="auth-container">
      <div className="placeholder-container">

        {/* Сообщения об ошибках и успехе */}
        {isVisible && (errorMessage || successMessage) && (
          <div className={`alert ${errorMessage ? 'error' : 'success'}`}>
            <span className="alert_message success">{errorMessage || successMessage}</span>
          </div>
        )}

        {/* Форма входа с email */}
        <div className="auth_form">
          <div className="container_span_logo_name">
              <div className="top_container_span_logo_name">Добро пожаловать на </div>
              <span>ANIPLAY<span style={{ color: '#ff4a6a' }}>.FUN</span></span>
              <div className="bottom_container_span_logo_name"><span>для любителей аниме</span></div>
              <div className="link_bottom_container_span_logo_name">
                <svg xmlns="http://www.w3.org/2000/svg" width="15px" height="15px" viewBox="0 0 32 32" fill="none">
                  <circle cx="16" cy="16" r="14" fill="url(#paint0_linear_87_7225)"></circle>
                  <path d="M22.9866 10.2088C23.1112 9.40332 22.3454 8.76755 21.6292 9.082L7.36482 15.3448C6.85123 15.5703 6.8888 16.3483 7.42147 16.5179L10.3631 17.4547C10.9246 17.6335 11.5325 17.541 12.0228 17.2023L18.655 12.6203C18.855 12.4821 19.073 12.7665 18.9021 12.9426L14.1281 17.8646C13.665 18.3421 13.7569 19.1512 14.314 19.5005L19.659 22.8523C20.2585 23.2282 21.0297 22.8506 21.1418 22.1261L22.9866 10.2088Z" fill="white"></path>
                  <defs>
                    <linearGradient id="paint0_linear_87_7225" x1="16" y1="2" x2="16" y2="30" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#37BBFE"></stop>
                      <stop offset="1" stop-color="#007DBB"></stop>
                    </linearGradient>
                  </defs>
                </svg>
                Мы в Telegram
              </div>
          </div>
          {!isCodeSent ? (
            <>
              <span className='cont_auth_form_span'>Введите ваш Email</span>
              <div className='cont_auth_form'>
                <input
                  className="email_con_input"
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                />
                <button className='email_con_butt' onClick={handleEmailSubmit}>Продолжить</button>
              </div>
            </>
          ) : (
            <>
              <span className='cont_auth_form_span'>Введите код</span>
              <div className='cont_auth_form'>
                <input
                  className="email_con_input"
                  type="text"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  placeholder="Код"
                />
                <button className='email_con_butt' onClick={handleCodeSubmit}>Подтвердить</button>
                <button className='email_back_butt' onClick={handleBack}>Назад</button>
              </div>
            </>
          )}
          <div className="auth_page_warning">Внимание! Нажимая "Продолжить", вы соглашаетесь с условиями пользовательского соглашения и политикой конфиденциальности.</div>
          <div className='bottone_line_agreement'>
            <a href='/welcome'>На главную</a>
            <div>
              <a href='/agreement'>Соглашение</a>
              <a href='/privacy'>Конфиденциальность</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
