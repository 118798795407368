import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";

const TestPlayer = () => {
  const { id } = useParams();
  const location = useLocation();
  const [isEpisodeMenuVisible, setEpisodeMenuVisible] = useState(false);
  const [isTranslationMenuVisible, setTranslationMenuVisible] = useState(false);
  const [selectedTranslation, setSelectedTranslation] = useState(null);
  const [selectedEpisode, setSelectedEpisode] = useState(null);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [playerSize, setPlayerSize] = useState({ width: 1118, height: 729 });

  useEffect(() => {
    const updatePlayerSize = () => {
      const containerWidth = window.innerWidth;
      const containerHeight = window.innerHeight;
      const baseWidth = 1032;
      const baseHeight = 680;

      const newWidth = (baseWidth / 1920) * containerWidth;
      const newHeight = (baseHeight / 1080) * containerHeight;

      setPlayerSize({
        width: newWidth,
        height: newHeight,
      });
    };

    window.addEventListener("resize", updatePlayerSize);
    updatePlayerSize();

    return () => {
      window.removeEventListener("resize", updatePlayerSize);
    };
  }, []);

  // Функция для переключения меню эпизодов
  const toggleEpisodeMenu = () => {
    setEpisodeMenuVisible(true);
    setTranslationMenuVisible(false); // Закрыть меню озвучек
  };

  // Функция для переключения меню озвучек
  const toggleTranslationMenu = () => {
    setTranslationMenuVisible(true);
    setEpisodeMenuVisible(false); // Закрыть меню эпизодов
  };

  const getEpisodesRange = (lastEpisode, episodesCount) => {
    const startEpisode = lastEpisode - episodesCount + 1;
    return Array.from({ length: episodesCount }, (_, i) => i + startEpisode).map(
      (episode) => `${episode} эпизод`
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      const token = process.env.REACT_APP_KODIK_TOKEN;

      if (!token) {
        console.error("Token is not defined");
        return;
      }

      const url = `https://kodikapi.com/search?token=${token}&shikimori_id=${id}&with_episodes_data=true`;
      try {
        const response = await fetch(url);
        if (!response.ok) {
          console.error("Error fetching data:", response.statusText);
          return;
        }
        const result = await response.json();

        if (result && result.results && result.results.length > 0) {
          setData(result.results);

          const firstTranslation = result.results[0].translation;
          const firstEpisode = getEpisodesRange(
            result.results[0].last_episode,
            result.results[0].episodes_count
          )[0];

          setSelectedTranslation(firstTranslation);
          
          // Define queryParams here
          const queryParams = new URLSearchParams(location.search);
          const episodeParam = queryParams.get("episode");
          if (episodeParam) {
            setSelectedEpisode(episodeParam);
          } else {
            setSelectedEpisode(firstEpisode);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (id) {
      fetchData();
    }
  }, [id, location.search]);

  const handleTranslationSelect = (translation) => {
    setSelectedTranslation(translation);
    setEpisodeMenuVisible(true);
    setTranslationMenuVisible(false); // Закрыть меню озвучек при выборе озвучки
  };

  // Получаем ссылку для плеера с учетом выбранной серии и перевода
  const getPlayerLink = () => {
    if (!selectedTranslation || !selectedEpisode) return ''; // Если не выбраны перевод или серия, возвращаем пустую строку

    // Ищем ссылку для выбранного перевода и серии
    const selectedItem = data.find(item => item.translation.id === selectedTranslation.id);
    if (selectedItem) {
      const episodeNumber = selectedEpisode.split(' ')[0]; // Извлекаем номер серии
      const baseLink = selectedItem.link; // Используем ссылку из API
      return `${baseLink}?episode=${episodeNumber}&hide_selectors=true`; // Формируем ссылку с параметром episode
    }
    return ''; // Если ссылка не найдена
  };

  const goToNextEpisode = () => {
    if (!selectedEpisode) return;

    const episodes = getEpisodesRange(data[0].last_episode, data[0].episodes_count);
    const currentIndex = episodes.indexOf(selectedEpisode);
    if (currentIndex < episodes.length - 1) {
      setSelectedEpisode(episodes[currentIndex + 1]);
    }
  };

  const goToPreviousEpisode = () => {
    if (!selectedEpisode) return;

    const episodes = getEpisodesRange(data[0].last_episode, data[0].episodes_count);
    const currentIndex = episodes.indexOf(selectedEpisode);
    if (currentIndex > 0) {
      setSelectedEpisode(episodes[currentIndex - 1]);
    }
  };

  const rewind90Seconds = () => {
    const iframe = document.querySelector('iframe');
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(
        '{"event":"command","func":"seekTo","args":[90]}',
        '*'
      );
    }
  };

  // Вычисление первой и последней серии
  const episodes = data && data.length > 0 && selectedTranslation
    ? getEpisodesRange(data[0].last_episode, data[0].episodes_count)
    : [];

  const isFirstEpisode = episodes.indexOf(selectedEpisode) === 0;
  const isLastEpisode = episodes.indexOf(selectedEpisode) === episodes.length - 1;

  return (
    <div className="player_page">
      <div style={{ margin: "0px 24px" }}>
        <div className="panel_player" style={{ width: playerSize.width, height: playerSize.height }}>
          <iframe src={getPlayerLink()} frameborder="0" allowfullscreen allow="autoplay *; fullscreen *"></iframe>
        </div>
        <div className="info_player">
          <span className="info_player_title">
            {data && data.length > 0 ? data[0].title : ""}
          </span>
          <div className="info_player_center">
            <div>{selectedTranslation ? selectedTranslation.title : ""}</div>
            <div>{selectedEpisode ? selectedEpisode : ""}</div>
          </div>
        </div>
        <div className="block_warning">
          <p>Kodic - на данный момент основной плеер. Имеющий рекламную вставку с сохранением времени. Неимеющий качества 1080p.</p>
        </div>
      </div>

      <div className="nenu_catalof_player">

        {!isEpisodeMenuVisible && !isTranslationMenuVisible && (
          <>
            <div className="tab_header">
              <div className="tab_header_butt" onClick={toggleTranslationMenu}>
                Озвучка
              </div>
              <div className="line_tab_header_butt"></div>
              <div className="tab_header_butt" onClick={toggleEpisodeMenu}>
                Эпизод
              </div>
            </div>

            <div className="player_quick_action">
              <span>Быстрые действия</span>
              
              {/* Условие для отображения кнопки "Следующий эпизод" */}
              {!isLastEpisode && (
                <div className="block_player_quick_action" onClick={goToNextEpisode}>
                  <div>Следующий эпизод</div>
                </div>
              )}

              {/* Условие для отображения кнопки "Предыдущий эпизод" */}
              {!isFirstEpisode && (
                <div className="block_player_quick_action" onClick={goToPreviousEpisode}>
                  <div>Предыдущий эпизод</div>
                </div>
              )}

              <div className="block_player_quick_action" onClick={rewind90Seconds}>
                <div>Перемотать на 90 секунд</div>
              </div>
            </div>

            <div className="player_info_hotkeys">
              <span>Горячие клавиши</span>
              <div className="line_player_info_hotkeys">
                <div>F</div>
                <p>Полноэкранный режим</p>
              </div>
              <div className="line_player_info_hotkeys">
                <div>SPACE</div>
                <p>Пауза/Плей</p>
              </div>
              <div className="line_player_info_hotkeys">
                <div>M</div>
                <p>Выкл\Вкл звук</p>
              </div>
              <div className="line_player_info_hotkeys">
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" style={{ transform: 'rotate(180deg)' }}>
                    <path d="M4 12H20M4 12L8 8M4 12L8 16" stroke="#ffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </div>
                <p>Перемотка вперед</p>
              </div>
              <div className="line_player_info_hotkeys">
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none">
                    <path d="M4 12H20M4 12L8 8M4 12L8 16" stroke="#ffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </div>
                <p>Перемотка назад</p>
              </div>
            </div>
          </>
        )}

        {/* Вкладка Эпизоды */}
        {isEpisodeMenuVisible && (
          <div className="episode_menu">
            <div className="container_menu_back" onClick={() => setEpisodeMenuVisible(false)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                <path d="M4 12H20M4 12L8 8M4 12L8 16" stroke="#ffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              Скрыть
            </div>
            <div className="container_menu_episode">
              <p className="container_menu_title">Эпизоды</p>
              <div className="overflow_slider_episode">
              {data && data.length > 0 && selectedTranslation ? (
                data
                  .filter((item) => item.translation.id === selectedTranslation.id)
                  .map((item, index) => {
                    const episodes = getEpisodesRange(item.last_episode, item.episodes_count);
                    const lastSeason = item.last_season;
                    return episodes.map((episode, i) => {
                      // Находим скриншоты для текущего эпизода
                      const episodeIndex = episode.split(' ')[0]; // Извлекаем номер эпизода
                      const episodeScreenshots = item.seasons[lastSeason]?.episodes[episodeIndex]?.screenshots;

                      return (
                        <div className="container_menu_list" key={`${index}-${i}`} onClick={() => setSelectedEpisode(episode)}>
                          <div className="container_menu_list_item">
                            <p className="container_menu_episode_info">
                              {episode}
                            </p>
                            <div className="container_menu_image">
                              <div className="container_menu_image_block">
                                {episodeScreenshots && episodeScreenshots.length > 0 ? (
                                  <img src={episodeScreenshots[0]} alt={`Скриншот эпизода ${episode}`} />
                                ) : (
                                  <img src="../icons/item.jpg" alt="Эпизод" />
                                )}
                              </div>
                              <div className="overflow_container_menu_list_item"></div>
                            </div>
                          </div>
                        </div>
                      );
                    });
                  })
              ) : (
                <></>
              )}
              </div>
            </div>
          </div>
        )}

        {/* Вкладка Озвучка */}
        {isTranslationMenuVisible && (
          <div className="episode_menu">
            <div className="container_menu_back" onClick={() => setTranslationMenuVisible(false)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                <path d="M4 12H20M4 12L8 8M4 12L8 16" stroke="#ffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              Скрыть
            </div>
            <div className="container_menu_episode">
              <p className="container_menu_title">Озвучка</p>
              <div className="overflow_slider_episode">
                {data && data.length > 0 ? (
                  data.map((item, index) => (
                    <div className="container_menu_list" key={index} onClick={() => handleTranslationSelect(item.translation)}>
                      <div className="container_menu_list_item">
                        <p className="container_menu_episode_info">
                          {item.translation.title}
                        </p>
                        <p className="container_menu_translation_info">Озвучили {item.episodes_count} эпизодов</p>
                      </div>
                    </div>
                  ))
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TestPlayer;
