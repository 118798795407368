import React from 'react'
import Auth from '../components/Auth';
import { Helmet } from "react-helmet";

function login() {
  return (
    <div>
      <Helmet>
        <title>Авторизация</title>
      </Helmet>
      <Auth />
    </div>
  )
}

export default login
