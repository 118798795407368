import React, { useState, useRef, useEffect,  } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify';

export default function Settings() {
  const [activeTab, setActiveTab] = useState('account');
  const [avatar, setAvatar] = useState(null);
  const [banner, setBanner] = useState(null);
  const [email, setEmail] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('');
  const [description, setDescription] = useState('');

  const avatarInputRef = useRef(null);
  const bannerInputRef = useRef(null);
  const emailInputRef = useRef(null);

  const [isTogglerActive1, setTogglerActive1] = useState(false); // Состояние для первого переключателя
  const [isTogglerActive2, setTogglerActive2] = useState(false); // Состояние для второго переключателя
  const [isTogglerActive3, setTogglerActive3] = useState(false); // Состояние для третьего переключателя
  const [isTogglerActive4, setTogglerActive4] = useState(false); // Состояние для четвертого переключателя

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/auth'); // Перенаправляем на страницу авторизации, если токена нет
      return;
    }

    async function fetchProfile() {
      try {
        const response = await fetch('https://api.aniplay.fun/api/user/profile', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        if (!response.ok) throw new Error('Ошибка загрузки данных профиля');
        const data = await response.json();

        setAvatar(data.avatar || null);
        setEmail(data.email || null);
        setBanner(data.banner || null);
        setStatus(data.status || ''); // Перемещено сюда
        setDescription(data.description || ''); // Перемещено сюда
      } catch (err) {
        displayAlert(err.message, 'error');
      }
    }
    fetchProfile();
  }, []); // Этот useEffect будет вызываться один раз при монтировании компонента

  const displayAlert = (message, type) => {
    if (type === 'error') setErrorMessage(message);
    if (type === 'success') setSuccessMessage(message);
    setIsVisible(true);
    setTimeout(() => setIsVisible(false), 3000); // Сообщение исчезает через 3 секунды
  };

  const handleFileClick = (inputRef) => {
    inputRef.current.click();
  };

  const handleFileChange = (event, type) => {
    const file = event.target.files[0];
    if (file) {
      const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
      if (!allowedTypes.includes(file.type)) {
        displayAlert('Неверный формат файла. Разрешены только JPEG, PNG и GIF.', 'error');
        return;
      }
      uploadFile(file, type);
    }
  };

  const uploadFile = async (file, type) => {
    setLoading(true);
    const formData = new FormData();
    formData.append(type, file);

    try {
      const response = await fetch(`https://api.aniplay.fun/upload-${type}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || `Ошибка загрузки ${type}`);
      }

      const data = await response.json();
      if (type === 'avatar') setAvatar(data.avatarUrl);
      if (type === 'banner') setBanner(data.bannerUrl);

      displayAlert('Файл успешно загружен!', 'success');
    } catch (err) {
      displayAlert(err.message, 'error');
    } finally {
      setLoading(false);
    }
  };

  const saveSettings = async () => {
    if (!avatar || !banner ) {
      displayAlert('Все поля должны быть заполнены', 'error');
      return;
    }

    try {
      setLoading(true);
      const response = await fetch('https://api.aniplay.fun/api/save-settings', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
          avatar,
          banner,
          status,
          description,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Ошибка сохранения настроек');
      }

      const result = await response.json();
      displayAlert(result.message, 'success');
    } catch (err) {
      displayAlert(err.message, 'error');
    } finally {
      setLoading(false);
    }
  };


  const handleDescriptionChange = (e) => {
    // Очищаем ввод от любых скриптов и тегов
    const sanitizedDescription = DOMPurify.sanitize(e.target.value);
  
    // Ограничиваем длину до 25 символов
    if (sanitizedDescription.length <= 27) {
      setDescription(sanitizedDescription);
    }
  };

  const navigate = useNavigate();

  return (
    <div className="body_settings">
      <div className="header_settings">
        <span>Настройки</span>
        <div className="link_header_settings">
          {['account'].map((tab) => (
            <div
              key={tab}
              className={activeTab === tab ? 'activ_link_settings' : ''}
              onClick={() => setActiveTab(tab)}
            >
              {tab === 'account' && 'Аккаунт'}
            </div>
          ))}
        </div>
      </div>

      <div className={`alert error ${isVisible ? 'fade-in' : 'fade-out'}`}>
        {errorMessage && <span className="alert_message error">{errorMessage}</span>}
        {successMessage && <span className="alert_message success">{successMessage}</span>}
      </div>

      <div className="main_settings">
        {activeTab === 'account' && (
          <div className="account_main_settings">
            <div className='render_page_h1'>
              <div className='render_page_h1_span'>
                <span>Аккаунт</span>
                <p>Добавьте сведения о себе и настройте внешний вид</p>
                <div className='render_page_h1_p'>Вы вошли в аккаунт {email}</div>
              </div>
              <div className='render_page_h1_image_logo'>
                <img src='../icons/logo_no_fon.png'/>
              </div>
            </div>
            <div class="line_contents_item_appearance"></div>
            <div className='render_page_h2'>
              <div className='render_page_h2_span'>
                <span>Ваш аккаунт</span>
              </div>
              <div className="item_appearance" onClick={() => handleFileClick(avatarInputRef)}>
                <div className="name_item_appearance">Аватарка</div>
                <input
                  type="file"
                  ref={avatarInputRef}
                  style={{ display: 'none' }}
                  onChange={(e) => handleFileChange(e, 'avatar')}
                />
                {avatar ? (
                  <img className='file_user_avatar' src={avatar} alt="Avatar Preview" />
                ) : (
                  <div className='desc_item_appearance'>Кликните, чтобы установить аватарку</div>
                )}
              </div>
              <div className="item_appearance" onClick={() => handleFileClick(bannerInputRef)}>
                <div className="name_item_appearance">Баннер</div>
                <input
                  type="file"
                  ref={bannerInputRef}
                  style={{ display: 'none' }}
                  onChange={(e) => handleFileChange(e, 'banner')}
                />
                {banner ? (
                  <img className='file_user_banner' src={banner} alt="Banner Preview" />
                ) : (
                  <div className='desc_item_appearance'>Кликните, чтобы установить баннер</div>
                )}
              </div>
              <div className='item_appearance'>
                <div className="name_item_appearance">Описание</div>
                <input
                  type="text"
                  className="textarea_item_appearance"
                  placeholder="Введите описание"
                  value={description}
                  onChange={handleDescriptionChange}
                />
              </div>
            </div>

            <div className="save_button">
              <button onClick={saveSettings} disabled={loading}>
                {loading ? 'Сохранение...' : 'Сохранить настройки'}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
