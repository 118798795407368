import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import axios from 'axios';

// GraphQL query to get anime announcements
const GET_ANIME_ANNOUNCEMENTS = gql`
  query GetAnimeAnnouncements($genre: String!) {
    animes(genre: $genre, limit: 100, order: popularity) {
      id
      russian
      score
      episodes
      poster {
        originalUrl
      }
      genres {
        russian
        kind
      }
      description
    }
  }
`;

export default function GenreAll() {
    const { id: genreId } = useParams();  
    const { loading, error, data } = useQuery(GET_ANIME_ANNOUNCEMENTS, {
      variables: { genre: genreId },
    });
    const [expandedItem, setExpandedItem] = useState(null);
    const [serverData, setServerData] = useState([]);
    const [animeDetails, setAnimeDetails] = useState({}); // Для хранения данных из API

    const genres = {
        "4-Comedy": "Комедия",
        "22-Romance": "Романтика",
        "2-Adventure": "Приключения",
        "37-Supernatural": "Сверхъестественное",
        "8-Drama": "Драма",
    };

    const currentGenreName = genres[genreId] || "Неизвестный жанр";

    const fetchServerData = async () => {
      try {
        const response = await axios.get('https://api.aniplay.fun/anime');
        setServerData(response.data);
      } catch (err) {
        console.error(err);
      }
    };

    // Функция для получения данных с API по id аниме
    const fetchAnimeData = async (id) => {
      try {
        const response = await axios.get(`https://api.aniplay.fun/storage/${id}`);
        return response.data;
      } catch (err) {
        console.error(err);
        return null; // Возвращаем null в случае ошибки
      }
    };

    useEffect(() => {
      fetchServerData();
    }, []);

    // Получаем данные о каждом аниме после загрузки аниме
    useEffect(() => {
      const fetchAllAnimeDetails = async () => {
        const details = await Promise.all(
          (data?.animes || []).map(anime => fetchAnimeData(anime.id))
        );
        const detailsMap = {};
        details.forEach((detail, index) => {
          if (detail) {
            detailsMap[data.animes[index].id] = detail;
          }
        });
        setAnimeDetails(detailsMap);
      };

      if (data) {
        fetchAllAnimeDetails();
      }
    }, [data]);

    const findAnimeOnServer = (id) => {
      return serverData.find((anime) => anime.id === Number(id));
    };

    const handleItemClick = (index) => {
      setExpandedItem(index === expandedItem ? null : index);
    };

    function renderScoreWithSVG(score) {
      if (score >= 8) {
        return <>{score}</>;
      } else {
        return <>{score}</>;
      }
    }

    if (loading) return (
      <div className='all_header'>
        <div className='open_header'>
            <span>{currentGenreName}</span>
        </div>
        <div className='open_list'>
          {Array.from({ length: 21 }, (_, index) => (
            <div key={index} className='item_open_list' style={{ display: "block" }}>
              <div className='item_all loadbar_item_all'>
                <div className='loading-bar'></div>
              </div>
              <div className='overlay'></div>
              <div className="item_name loadbar_item_name">
                <div className='loading-bar'></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );

    if (error) return <p>Error: {error.message}</p>;

    const animes = data?.animes || [];

    return (
      <div className='all_header'>
        <div className='open_header'>
            <span>{currentGenreName}</span>
        </div>
        <div className='open_list'>
          {animes.map((anime) => {
            const serverAnime = findAnimeOnServer(anime.id);
            const displayAnime = serverAnime || anime;

            return (
              <div key={anime.id} className='item_open_list'>
                <a href={`/anime/${anime.id}`}>
                  <div className='item_all'>
                    <div className='container_catalog_item_tag'>
                      {serverAnime && (
                        <div className='item_tag' style={{ backgroundColor: 'rgb(255, 63, 63)', color: 'white', fontWeight: '500' }}>4K</div>
                      )}
                      <div
                        className={`item_tag ${displayAnime.score >= 8 ? 'hard_score' : displayAnime.score <= 7 ? 'low_score' : 'high_score'}`}
                      >
                        {renderScoreWithSVG(anime.score)}
                      </div>
                    </div>
                    {!serverAnime && <div className='poster_overlay'></div>}
                    {animeDetails[anime.id]?.sap ? (
                      <img
                        src={animeDetails[anime.id].sap}
                        style={{ backgroundImage: `url('https://aniplay.fun/icons/fon_item.jpg')` }}
                      />
                    ) : (
                      <img
                        src={anime.poster?.originalUrl}
                        style={{ backgroundImage: `url('https://aniplay.fun/icons/fon_item.jpg')` }}
                      />
                    )}
                    <div className="item_name">{displayAnime.russian || displayAnime.title}</div>
                    <div className='overlay'></div>
                  </div>
                </a>
              </div>
            );
          })}
        </div>
      </div>
    );
}
