import React, { Component } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Collection from '../components/Collection'


export default class collection extends Component {
  render() {
    return (
      <div className='collection'>
        <Header />
        <Collection />
        <Footer />
      </div>
    )
  }
}
