import React, { useEffect, useRef, useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import axios from 'axios';

// GraphQL query to get anime announcements
const GET_ANIME_ANNOUNCEMENTS = gql`
  query GetAnimeAnnouncements {
    animes(kind: "tv", status: "latest", limit: 100, order: ranked_random, rating: "pg_13") {
      id
      russian
      score
      episodes
      poster {
        originalUrl
      }
      genres {
        russian
        kind
      }
      description
    }
  }
`;

export default function AllOpenList() {
  const { loading, error, data } = useQuery(GET_ANIME_ANNOUNCEMENTS);
  const [expandedItem, setExpandedItem] = useState(null);
  const [serverData, setServerData] = useState([]);

  // Функция для получения данных с вашего сервера
  const fetchServerData = async () => {
    try {
      const response = await axios.get('https://api.aniplay.fun/anime');
      setServerData(response.data);
    } catch (err) {
      console.error(err);
    }
  };

  // Fetch server data when component mounts
  useEffect(() => {
    fetchServerData();
  }, []);

  // Функция для проверки наличия аниме в серверных данных
  const findAnimeOnServer = (id) => {
    return serverData.find((anime) => anime.id === Number(id));
  };

  const handleItemClick = (index) => {
    setExpandedItem(index === expandedItem ? null : index);
  };


  const cleanDescription = (description) => {
    return description ? description.replace(/\[.*?\]/g, '') : 'Нет описания';
  };

  function renderScoreWithSVG(score) {
    if (score >= 8) {
      return (
        <>
          {score}
        </>
      );
    } else {
      return (
        <>{score}</>
      );
    }
  }

  if (loading) return (
    <div className='all_header'>
      <div className='open_header'>
          <span>Недавно вышедшие</span>
      </div>
      <div className='open_list'>
        {Array.from({ length: 24 }, (_, index) => (
          <div key={index} className='item_open_list' style={{ display: "block" }}>
            <div className='item_all loadbar_item_all'>
              <div className='loading-bar'></div>
            </div>
            <div className='overlay'></div>
            <div class="item_name loadbar_item_name">
              <div className='loading-bar'></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  if (error) return <p>Error: {error.message}</p>;

  const animes = data?.animes || [];

  return (
    <div className='all_header'>
      <div className='open_header'>
          <span>Недавно вышедшие</span>
      </div>
      <div className='open_list'>
        {animes.map((anime) => {
          const serverAnime = findAnimeOnServer(anime.id);
          const displayAnime = serverAnime || anime;

          return (
            <div key={anime.id} className='item_open_list'>
              <a href={`/anime/${anime.id}`}>
                <div className='item_all'>
                  <div className='container_catalog_item_tag'>
                    {serverAnime && (
                      <div className='item_tag' style={{ backgroundColor: 'rgb(255, 63, 63)', color: 'white', fontWeight: '500' }}>4K</div>
                    )}
                    <div
                      className={`item_tag ${
                        displayAnime.score >= 8 ? 'hard_score' : displayAnime.score <= 7 ? 'low_score' : 'high_score'
                      }`}
                    >
                      {renderScoreWithSVG(anime.score)}
                    </div>
                  </div>
                  {!serverAnime && <div className='poster_overlay'></div>}
                  {displayAnime.poster ? (
                    <img
                      src={anime.poster?.originalUrl}
                      style={{ backgroundImage: `url('https://aniplay.fun/icons/fon_item.jpg')` }}
                    />
                  ) : (
                    <div className="no-poster">Постер не доступен</div>
                  )}
                  <div className="item_name">{displayAnime.russian || displayAnime.title}</div>
                  <div className='overlay'></div>
                </div>
              </a>
            </div>
          );
        })}
      </div>
    </div>
  );
}
