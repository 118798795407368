import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../components/Header';
import PlayEpisode from '../components/PlayEpisode';
import AnimePlay from '../components/Anime';
import { gql, useQuery } from '@apollo/client';

const GET_ANIME_BY_ID = gql`
  query GetAnimeById($id: String!) {
    animes(ids: $id) {
      id
      name
      russian
      japanese
      score
      kind
      status
      episodesAired
      description
      duration
      airedOn {
        year
      }
      genres {
        name
        russian
        kind
      }
      airedOn {
        date
      }
      poster {
        originalUrl
      }
      screenshots {
        originalUrl
      }
      studios {
        name
      }
      nextEpisodeAt
      related {
        anime {
          id
          name
          russian
          score
          poster {
            originalUrl
          }
        }
      }
      videos {
        url
        name
        kind
        imageUrl
      }
      scoresStats {
        score
        count
      }
      externalLinks {
        id
        kind
        url
        createdAt
        updatedAt
      }
      statusesStats { 
        status 
        count 
      }
    }
  }
`;

const Anime = () => {
  const { id } = useParams();
  const { loading, error, data } = useQuery(GET_ANIME_BY_ID, {
    variables: { id }
  });

  if (loading)     
  return (
    <div className="kp-loader">
      <div className="kp-loader-circle"></div>
    </div>
  );
  if (error || !data || !data.animes.length) {
    return (
      <div class="error_404">
        <div class="line_top_error_404">
          <img src="../icons/logo_no_fon.png" />
          <p>Аниме не найденно или вы неправильно указали адрес страницы!</p>
        </div>
        <div class="line_center_error_404">
          <img src="../icons/eror_image.gif" style={{width: '200px'}} />
          <a href="/welcom">Главная</a>
        </div>
        <div class="line_bottom_error_404">
          <a href="">Не терпи и напиши нам в техническую поддержку</a>
        </div>
      </div>
    );
  }

  const anime = data.animes[0];

  return (
    <div className="anime">
      <Header />
      <AnimePlay anime={anime} />
    </div>
  );
};

export default Anime;
