import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Для редиректа
import Footer from '../components/Footer';

const ProfileUserInfo = () => {
  const [profileData, setProfileData] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Хук для навигации

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await fetch('https://api.aniplay.fun/api/user/profile', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`, // Добавляем токен авторизации
            'Content-Type': 'application/json', // Указываем тип контента
          },
        });

        if (response.status === 401) {
          // Если токен истек или неверный, удаляем токен и переадресуем на /auth
          localStorage.removeItem('token');
          navigate('/auth');
          return;
        }

        if (!response.ok) {
          throw new Error('Ошибка при получении данных пользователя');
        }

        const data = await response.json();
        setProfileData(data);
      } catch (err) {
        setError(err.message);
        // Если произошла ошибка, также удаляем токен и переадресуем на страницу авторизации
        localStorage.removeItem('token');
        navigate('/auth');
      }
    };

    fetchProfile();
  }, [navigate]);

  if (error) {
    return (
      <div className='error_404'>
        <div className='line_top_error_404'>
          <img src='../icons/logo_no_fon.png'></img>
          <p>Аниме не найденно или вы неправильно указали адрес страницы!</p>
        </div>
        <div className='line_center_error_404'>
          <img src='../icons/eror_image.gif' style={{width: '200px'}}></img>
          <a href='/welcomw'>Главная</a>
        </div>
        <div className='line_bottom_error_404'>
          <a href=''>Не терпи и напиши нам в техническую поддержку</a>
        </div>
      </div>
    );
  }

  if (!profileData) {
    return (
      <div className="kp-loader">
        <div className="kp-loader-circle"></div>
      </div>
    );
  }

  return (
    <div className='profile_main_user_info'>
      <div className='profile_user_main'>
        <div className='profile_user_banner'>
          <img 
            className='user_avatar_banner'
            src={profileData.banner}
            alt='User banner' 
          />
          <div className='overflow_user_banner'></div>
        </div>
        <div className='profile_user_main_avatar'>
          <img className='user_avatar_profile' src={profileData.avatar} alt='User avatar' />
          <div className='profile_user_name'>
            <div>{profileData.login}</div>
          </div>
        </div>
        <div className='profile_user_main_descript'>
          {profileData.description}
        </div>
      </div>
      <div className='level_user_progess'>
        <span>Ваш текущий уровень<br/>(технология еще в разработке)</span>
        <div className='level_point'>1</div>
        <div className='lever_status_line'>
          <div className='leverl_stage_line'>
            <svg xmlns="http://www.w3.org/2000/svg" fill="#ffff" width="12" height="12" viewBox="0 0 24 24">
              <path d="M11.178 19.569a.998.998 0 0 0 1.644 0l9-13A.999.999 0 0 0 21 5H3a1.002 1.002 0 0 0-.822 1.569l9 13z"/>
            </svg>
          </div>
          <div className='leverl_stage_line'></div>
          <div className='leverl_stage_line'></div>
          <div className='leverl_stage_line'></div>
          <div className='leverl_stage_line'></div>
        </div>
        <div className='name_lever_status_line'>
          <div style={{color: '#fff'}}>Неопытный Исекайщик</div>
          <div>Прокачанный Ота-кун</div>
          <div>Гуру гайдов</div>
          <div>Мастер аркан</div>
          <div>Сама Ояка́та!</div>
        </div>
      </div>
      <div style={{height: '200px'}}></div>
      <Footer />
    </div>
  );
};

export default ProfileUserInfo;
