import React, { useRef, useEffect } from 'react';

export default function ListCatalogTop10() {
  const listCatalogRef = useRef(null);

  useEffect(() => {
    const observerOptions = {
      threshold: 0.9
    };

    // Массив с разными цветами для <stop> по индексам
    const stopColors = ['#FFFF', '#FFFF', '#FFFF', 'hsla(0, 0%, 100%, .15)'];

    const handleIntersection = (entries) => {
      entries.forEach(entry => {
        const stops = entry.target.querySelectorAll('svg stop');
        if (entry.isIntersecting) {
          stops.forEach((stop, index) => {
            // Сохраняем исходный цвет, если он еще не сохранен
            if (!stop.dataset.originalColor) {
              stop.dataset.originalColor = stop.getAttribute('stop-color');
            }
            // Устанавливаем плавный переход
            stop.style.transition = 'stop-color 1s';
            // Меняем цвет на соответствующий из массива
            const newColor = stopColors[index % stopColors.length];
            stop.setAttribute('stop-color', newColor);
          });
        } else {
          stops.forEach(stop => {
            if (stop.dataset.originalColor) {
              // Восстанавливаем исходный цвет
              stop.setAttribute('stop-color', stop.dataset.originalColor);
            }
          });
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, observerOptions);

    const topSelectItems = listCatalogRef.current.querySelectorAll('.top_select_item');

    topSelectItems.forEach(item => {
      observer.observe(item);
    });

    // Очистка при размонтировании компонента
    return () => {
      topSelectItems.forEach(item => {
        observer.unobserve(item);
      });
    };
  }, []);


  return (
    <div className='list_catalog'>
      <div className='list_catalog_header'>
        <span style={{
          fontSize: '21px',
          fontWeight: '600'
        }}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="hsla(0, 0%, 100%, .15)" height="25px" width="25px" version="1.1" viewBox="0 0 326.387 326.387" style={{opacity: 1}}>
            <polygon points="252.086,178.355 171.154,144.27 231.9,0 74.301,148.018 155.236,182.104 94.487,326.387 " />
          </svg>
          Топ-5 за месяц
        </span>
      </div>
      <div className='top_list_episode' ref={listCatalogRef}>
        <a href='/anime/58567'>
          <div className="top_select_item">
            <svg width="200" height="140" viewBox="0 0 214 165" fill="none" xmlns="http://www.w3.org/2000/svg" >
              <path d="M77 32.4018L70 0H164L128.5 135.589H214V165H0L12 135.589H93L122 32.4018H77Z" fill="url(#paint0_linear_1_17)" />
              <defs>
                <linearGradient id="paint0_linear_1_17" x1="107" y1="0" x2="107" y2="165" gradientUnits="userSpaceOnUse" >
                  <stop stopColor="#ffffff5e" />
                  <stop offset="0.405" stopColor="#ffffff5e" />
                  <stop offset="0.72" stopColor="#ffffff5e" />
                  <stop offset="1" stopColor="#ffffff5e" />
                </linearGradient>
              </defs>
            </svg>
            <div className="top_catalog_item">
              <div className='poster_overlay'></div>
              <img src="https://nyaa.shikimori.one/uploads/poster/animes/58567/main-0b25e980c82263b348d57b5a0ac47025.webp"/>
            </div>
          </div>
        </a>
        <a href='/anime/58514'>
          <div className="top_select_item">
              <svg width="200" height="140" viewBox="0 0 214 165" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.58907 56.3011H44.6222C44.6222 -5.88024 337.227 33.5172 15.2133 131.299L0 165H214V131.299H114.096C368.668 -4.45625 -0.00136235 -44.3283 6.58907 56.3011Z" fill="url(#paint0_linear_1_25)"/>
                  <defs>
                      <linearGradient id="paint0_linear_1_25" x1="107" y1="0" x2="107" y2="165" gradientUnits="userSpaceOnUse">
                          <stop stop-color="#ffffff5e"/>
                          <stop offset="0.405" stop-color="#ffffff5e"/>
                          <stop offset="0.72" stop-color="#ffffff5e"/>
                          <stop offset="1" stop-color="#ffffff5e"/>
                      </linearGradient>
                  </defs>
              </svg>
              <div className='top_catalog_item'>
                  <div className='poster_overlay'></div>
                  <img src='https://dere.shikimori.one/uploads/poster/animes/58514/main-63734ca3432d6d8e04009b670cf2b0b3.webp'/>
              </div>
          </div>
        </a>
        <a href='/anime/58939'>
          <div className='top_select_item'>
              <svg width="200" height="140" viewBox="0 0 214 165" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13.5978 26.4511L21.6558 0H205.478L173.75 41.3008C308.721 116.942 66.4783 249.197 0 90.0265H28.7065C113.819 238.06 311.239 10.6732 71.0109 90.0265L62.4493 64.5035L124.395 26.4511H13.5978Z" fill="url(#paint0_linear_1_31)"/>
                  <defs>
                      <linearGradient id="paint0_linear_1_31" x1="107" y1="0" x2="107" y2="165" gradientUnits="userSpaceOnUse">
                          <stop stop-color="#ffffff5e"/>
                          <stop offset="0.405" stop-color="#ffffff5e"/>
                          <stop offset="0.72" stop-color="#ffffff5e"/>
                          <stop offset="1" stop-color="#ffffff5e"/>
                      </linearGradient>
                  </defs>
              </svg>
              <div className='top_catalog_item'>
                  <div className='poster_overlay'></div>
                  <img src='https://dere.shikimori.one/uploads/poster/animes/58939/main-ddada58c6d45b848598b4f1b5cae00bc.webp'/>
              </div>
          </div>
        </a>
        <a href='/anime/57592'>
          <div className='top_select_item'>
              <svg width="200" height="140" viewBox="0 0 214 165" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M21.5877 0H53.9693L39.8904 73.2154H140.397L155.807 0H188.189L173.82 73.2154H214L208.368 104.518H167.677L155.807 165H121.079L133.809 104.518H0L21.5877 0Z" fill="url(#paint0_linear_1_37)"/>
                  <defs>
                      <linearGradient id="paint0_linear_1_37" x1="107" y1="0" x2="107" y2="165" gradientUnits="userSpaceOnUse">
                          <stop stop-color="#ffffff5e"/>
                          <stop offset="0.405" stop-color="#ffffff5e"/>
                          <stop offset="0.72" stop-color="#ffffff5e"/>
                          <stop offset="1" stop-color="#ffffff5e"/>
                      </linearGradient>
                  </defs>
              </svg>
              <div className='top_catalog_item'>
                  <div className='poster_overlay'></div>
                  <img src='https://dere.shikimori.one/uploads/poster/animes/57592/main-14c805424addb36f416a6d8f740fe206.webp'/>
              </div>
          </div>
        </a>
        <a href='/anime/56701'>
          <div className='top_select_item'>
              <svg width="200" height="140" viewBox="0 0 214 165" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M122.077 0H32.1255L16.5982 84.4724C325.002 27.3305 129.135 210.476 36.4089 104.766H0C140.022 282.724 375.742 4.78382 48.9237 54.5356L57.9998 22.0056H115.826L122.077 0Z" fill="url(#paint0_linear_1_44)"/>
                  <defs>
                      <linearGradient id="paint0_linear_1_44" x1="107" y1="0" x2="107" y2="165" gradientUnits="userSpaceOnUse">
                          <stop stop-color="#ffffff5e"/>
                          <stop offset="0.405" stop-color="#ffffff5e"/>
                          <stop offset="0.72" stop-color="#ffffff5e"/>
                          <stop offset="1" stop-color="#ffffff5e"/>
                      </linearGradient>
                  </defs>
              </svg>
              <div className='top_catalog_item'>
                  <div className='poster_overlay'></div>
                  <img src='https://dere.shikimori.one/uploads/poster/animes/56701/main-b22ced8bd6ab3c6df8ac26a424f4ed75.webp'/>
              </div>
          </div>
        </a>
      </div>
    </div>
  );
}
