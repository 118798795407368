import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useLazyQuery, gql } from '@apollo/client';
import axios from 'axios';

// GraphQL query to get anime announcements
const GET_ANIME_ANNOUNCEMENTS = gql`
  query GetAnimeAnnouncements($id: String!) {
    animes(ids: $id) {
      id
      russian
      score
      episodes
      poster {
        originalUrl
      }
      genres {
        russian
        kind
      }
      description
    }
  }
`;

export default function Collection( {animeId} ) {
    const { userId } = useParams();
    const [animeIds, setAnimeIds] = useState([]);
    const [viewedAnimeIds, setViewedAnimeIds] = useState([]);
    const [activeTab, setActiveTab] = useState('remembered');
    const [animes, setAnimes] = useState([]);
    const [viewedAnimes, setViewedAnimes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate(); // Хук для навигации

    const [fetchAnime] = useLazyQuery(GET_ANIME_ANNOUNCEMENTS, {
        onCompleted: (data) => {
            if (data && data.animes) {
                if (activeTab === 'remembered') {
                    setAnimes(prev => [...prev, ...data.animes]);
                } else if (activeTab === 'viewed') {
                    setViewedAnimes(prev => [...prev, ...data.animes]);
                }
            }
            setLoading(false); // Устанавливаем loading в false после завершения загрузки
        },
        onError: (err) => {
            setError(err);
            console.error('GraphQL Error:', err);
            setLoading(false); // Устанавливаем loading в false при ошибке
        }
    });

    const fetchUser  = async () => {
        const token = localStorage.getItem('token');
        if (!token) {
            // Если токен отсутствует, перенаправляем на страницу авторизации
            navigate('/auth');
            return; // Выходим из функции
        }
        
        try {
            const response = await axios.get('https://api.aniplay.fun/api/collection', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const collection = response.data;

            // Разделяем аниме по типу коллекции
            const rememberedIds = collection
                .filter(item => item.collection_type === 'remembered')
                .map(item => item.anime_id);
            const viewedIds = collection
                .filter(item => item.collection_type === 'viewed')
                .map(item => item.anime_id);

            // Убираем дубликаты
            const uniqueRememberedIds = [...new Set(rememberedIds)];
            const uniqueViewedIds = [...new Set(viewedIds)];

            setAnimeIds(uniqueRememberedIds);
            setViewedAnimeIds(uniqueViewedIds);
        } catch (error) {
            console.error('Error fetching user collection:', error);
            setError(error);
        }
    };

    useEffect(() => {
        fetchUser ();
    }, [userId]);

    const fetchAllAnimes = async (ids) => {
        setLoading(true);
        setError(null);
        for (let i = 0; i < ids.length; i += 2) {
            const chunk = ids.slice(i, i + 2).join(',');
            await fetchAnime({ variables: { id: chunk } });
        }
    };

    useEffect(() => {
        if (activeTab === 'remembered' && animeIds.length > 0 && animes.length === 0) {
            fetchAllAnimes(animeIds);
        } else if (activeTab === 'viewed' && viewedAnimeIds.length > 0 && viewedAnimes.length === 0) {
            fetchAllAnimes(viewedAnimeIds);
        }
    }, [activeTab, animeIds, viewedAnimeIds]);

    const handleTabClick = (tab) => {
        if (tab !== activeTab) {
            setActiveTab(tab);
            // Загрузка аниме только если они еще не загружены
            if (tab === 'remembered' && animes.length === 0) {
                fetchAllAnimes(animeIds);
            } else if (tab === 'viewed' && viewedAnimes.length === 0) {
                fetchAllAnimes(viewedAnimeIds);
            }
        }
    };

    const handleRemoveFromCollection = async (animeId, type) => {
        const token = localStorage.getItem('token');
    
        if (!token) {
            navigate('/auth'); // Redirect to authentication page if token is absent
            return; // Exit the function
        }
    
        try {
            const response = await axios.delete(`https://api.aniplay.fun/api/collection/${animeId}/${type}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
    
            console.log('Response from server:', response.data);
            
            // Update state after successful deletion
            if (type === 'remembered') {
                setAnimes(prev => prev.filter(anime => anime.id !== animeId));
            } else if (type === 'viewed') {
                setViewedAnimes(prev => prev.filter(anime => anime.id !== animeId));
            }
        } catch (error) {
            console.error('Ошибка при удалении из коллекции:', error.response ? error.response.data : error);
        }
    };
    
    
    
    
    
    function renderScoreWithSVG(score) {
        if (score >= 8) {
            return (
                <>
                    {score}
                </>
            );
        } else {
            return (
                <>{score}</>
            );
        }
    }

    if (loading) {
        return (
            <div className='collection_header'>
                <div className='open_collection'>
                    <span>Моя коллекция</span>
                </div>
                <div className='link_collection_tablist'>
                    <div
                        className={`ul_link_collection_tablist ${activeTab === 'remembered' ? 'link_active' : ''}`}
                        onClick={() => handleTabClick('remembered')}
                    >
                        Запомненные
                    </div>
                    <div
                        className={`ul_link_collection_tablist ${activeTab === 'viewed' ? 'link_active' : ''}`}
                        onClick={() => handleTabClick('viewed')}
                    >
                        Просмотренные
                    </div>
                </div>
                {activeTab === 'remembered' && (
                    <div className='tab_open_list_remembered'>
                        {Array.from({ length: 5 }, (_, index) => (
                        <div key={index} className='item_open_list' style={{ display: "block" }}>
                            <div className='item_all loadbar_item_all'>
                            <div className='loading-bar'></div>
                            </div>
                            <div className='overlay'></div>
                            <div className="item_name loadbar_item_name">
                            <div className='loading-bar'></div>
                            </div>
                        </div>
                        ))}
                    </div>
                )}
                {activeTab === 'viewed' && (
                    <div className='tab_open_list_viewed'>
                        {Array.from({ length: 5 }, (_, index) => (
                        <div key={index} className='item_open_list' style={{ display: "block" }}>
                            <div className='item_all loadbar_item_all'>
                            <div className='loading-bar'></div>
                            </div>
                            <div className='overlay'></div>
                            <div className="item_name loadbar_item_name">
                            <div className='loading-bar'></div>
                            </div>
                        </div>
                        ))}
                    </div>
                )}
            </div>
        );
    }

    if (error) {
        return (
            <div className="kp-loader">
                <div className="kp-loader-circle"></div>
            </div>
        );
    }

    return (
        <div className='collection_header'>
            <div className='open_collection'>
                <span>Моя коллекция</span>
            </div>
            <div className='link_collection_tablist'>
                <div
                    className={`ul_link_collection_tablist ${activeTab === 'remembered' ? 'link_active' : ''}`}
                    onClick={() => handleTabClick('remembered')}
                >
                    Запомненные
                </div>
                <div
                    className={`ul_link_collection_tablist ${activeTab === 'viewed' ? 'link_active' : ''}`}
                    onClick={() => handleTabClick('viewed')}
                >
                    Просмотренные
                </div>
            </div>
            {activeTab === 'remembered' && (
                <div className='tab_open_list_remembered'>
                    {animes.length === 0 ? (
                        <p>Нет аниме в коллекции.</p>
                    ) : (
                        animes.map((anime) => (
                            <div key={anime.id} className='item_open_list'>
                                <a href={`/anime/${anime.id}`} onClick={(e) => {
                                    if (e.target.closest('.episode_item_close_button')) {
                                        e.preventDefault();
                                    }
                                }}>
                                    <div className='item_all'>
                                        <div className='episode_item_close_button' onClick={() => handleRemoveFromCollection(anime.id, 'remembered')}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="37px" height="37px" viewBox="0 -0.5 25 25" fill="none">
                                                <path d="M6.96967 16.4697C6.67678 16.7626 6.67678 17.2374 6.96967 17.5303C7.26256 17.8232 7.73744 17.8232 8.03033 17.5303L6.96967 16.4697ZM13.0303 12.5303C13.3232 12.2374 13.3232 11.7626 13.0303 11.4697C12.7374 11.1768 12.2626 11.1768 11.9697 11.4697L13.0303 12.5303ZM11.9697 11.4697C11.6768 11.7626 11.6768 12.2374 11.9697 12.5303C12.2626 12.8232 12.7374 12.8232 13.0303 12.5303L11.9697 11.4697ZM18.0303 7.53033C18.3232 7.23744 18.3232 6.76256 18.0303 6.46967C17.7374 6.17678 17.2626 6.17678 16.9697 6.46967L18.0303 7.53033ZM13.0303 11.4697C12.7374 11.1768 12.2626 11.1768 11.9697 11.4697C11.6768 11.7626 11.6768 12.2374 11.9697 12.5303L13.0303 11.4697ZM16.9697 17.5303C17.2626 17.8232 17.7374 17.8232 18.0303 17.5303C18.3232 17.2374 18.3232 16.7626 18.0303 16.4697L16.9697 17.5303ZM11.9697 12.5303C12.2626 12.8232 12.7374 12.8232 13.0303 12.5303C13.3232 12.2374 13.3232 11.7626 13.0303 11.4697L11.9697 12.5303ZM8.03033 6.46967C7.73744 6.17678 7.26256 6.17678 6.96967 6.46967C6.67678 6.76256 6.67678 7.23744 6.96967 7.53033L8.03033 6.46967ZM8.03033 17.5303L13.0303 12.5303L11.9697 11.4697L6.96967 16.4697L8.03033 17.5303ZM13.0303 12.5303L18.0303 7.53033L16.9697 6.46967L11.9697 11.4697L13.0303 12.5303ZM11.9697 12.5303L16.9697 17.5303L18.0303 16.4697L13.0303 11.4697L11.9697 12.5303ZM13.0303 11.4697L8.03033 6.46967L6.96967 7.53033L11.9697 12.5303L13.0303 11.4697Z" fill="#ffff"></path>
                                            </svg>
                                        </div>
                                        <div className='container_catalog_item_tag'>
                                            <div
                                            className={`item_tag ${
                                                anime.score >= 8 ? 'hard_score' : anime.score <= 7 ? 'low_score' : 'high_score'
                                            }`}
                                            >
                                            {renderScoreWithSVG(anime.score.toFixed(1))}
                                            </div>
                                        </div>
                                        <div className='poster_overlay'></div>
                                        {anime.poster ? (
                                            <img
                                            src={anime.poster?.originalUrl}
                                            style={{ backgroundImage: `url('https://aniplay.fun/icons/fon_item.jpg')` }}
                                            />
                                        ) : (
                                            <div className="no-poster">Постер не доступен</div>
                                        )}
                                        <div className="item_name">{anime.russian || anime.title}</div>
                                        <div className='overlay'></div>
                                    </div>
                                </a>
                            </div>
                        ))
                    )}
                </div>
            )}
            {activeTab === 'viewed' && (
                <div className='tab_open_list_viewed'>
                    {viewedAnimes.length === 0 ? (
                        <p>Нет просмотренных аниме.</p>
                    ) : (
                        viewedAnimes.map((anime) => (
                            <div key={anime.id} className='item_open_list'>
                                <a href={`/anime/${anime.id}`} onClick={(e) => {
                                    // Проверяем, была ли нажата кнопка закрытия
                                    if (e.target.closest('.episode_item_close_button')) {
                                        e.preventDefault(); // Предотвращаем переход по ссылке
                                    }
                                }}>
                                    <div className='item_all'>
                                        <div className='episode_item_close_button' onClick={() => handleRemoveFromCollection(anime.id, 'viewed')}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="37px" height="37px" viewBox="0 -0.5 25 25" fill="none">
                                                <path d="M6.96967 16.4697C6.67678 16.7626 6.67678 17.2374 6.96967 17.5303C7.26256 17.8232 7.73744 17.8232 8.03033 17.5303L6.96967 16.4697ZM13.0303 12.5303C13.3232 12.2374 13.3232 11.7626 13.0303 11.4697C12.7374 11.1768 12.2626 11.1768 11.9697 11.4697L13.0303 12.5303ZM11.9697 11.4697C11.6768 11.7626 11.6768 12.2374 11.9697 12.5303C12.2626 12.8232 12.7374 12.8232 13.0303 12.5303L11.9697 11.4697ZM18.0303 7.53033C18.3232 7.23744 18.3232 6.76256 18.0303 6.46967C17.7374 6.17678 17.2626 6.17678 16.9697 6.46967L18.0303 7.53033ZM13.0303 11.4697C12.7374 11.1768 12.2626 11.1768 11.9697 11.4697C11.6768 11.7626 11.6768 12.2374 11.9697 12.5303L13.0303 11.4697ZM16.9697 17.5303C17.2626 17.8232 17.7374 17.8232 18.0303 17.5303C18.3232 17.2374 18.3232 16.7626 18.0303 16.4697L16.9697 17.5303ZM11.9697 12.5303C12.2626 12.8232 12.7374 12.8232 13.0303 12.5303C13.3232 12.2374 13.3232 11.7626 13.0303 11.4697L11.9697 12.5303ZM8.03033 6.46967C7.73744 6.17678 7.26256 6.17678 6.96967 6.46967C6.67678 6.76256 6.67678 7.23744 6.96967 7.53033L8.03033 6.46967ZM8.03033 17.5303L13.0303 12.5303L11.9697 11.4697L6.96967 16.4697L8.03033 17.5303ZM13.0303 12.5303L18.0303 7.53033L16.9697 6.46967L11.9697 11.4697L13.0303 12.5303ZM11.9697 12.5303L16.9697 17.5303L18.0303 16.4697L13.0303 11.4697L11.9697 12.5303ZM13.0303 11.4697L8.03033 6.46967L6.96967 7.53033L11.9697 12.5303L13.0303 11.4697Z" fill="#ffff"></path>
                                            </svg>
                                        </div>
                                        <div className='container_catalog_item_tag'>
                                            <div
                                            className={`item_tag ${
                                                anime.score >= 8 ? 'hard_score' : anime.score <= 7 ? 'low_score' : 'high_score'
                                            }`}
                                            >
                                            {renderScoreWithSVG(anime.score.toFixed(1))}
                                            </div>
                                        </div>
                                        <div className='poster_overlay'></div>
                                        {anime.poster ? (
                                            <img
                                            src={anime.poster?.originalUrl}
                                            style={{ backgroundImage: `url('https://aniplay.fun/icons/fon_item.jpg')` }}
                                            />
                                        ) : (
                                            <div className="no-poster">Постер не доступен</div>
                                        )}
                                        <div className="item_name">{anime.russian || anime.title}</div>
                                        <div className='overlay'></div>
                                    </div>
                                </a>
                            </div>
                        ))
                    )}
                </div>
            )}
        </div>
    );
}
