import React, { useEffect, useState } from 'react';

const emojis = ['🐱', '😸', '🐶', '🐶', '🐶', '🐺', '🐺']; // Эмодзи для снежинок

const Snowfall = () => {
  const [flakes, setFlakes] = useState([]);

  useEffect(() => {
    // Генерация снежинок
    const generateFlakes = () => {
      const newFlakes = Array.from({ length: 100 }).map((_, index) => ({
        id: index,
        emoji: emojis[Math.floor(Math.random() * emojis.length)], // Рандомный смайлик
        left: Math.random() * 100, // Позиция по оси X
        animationDuration: Math.random() * 5 + 25, // Длительность анимации
        animationDelay: Math.random() * -30, // Задержка анимации
        fontSize: Math.random() * 6 + 8, // Размер шрифта
        rotate: Math.random() * 720 - 360, // Рандомный угол поворота
        right: Math.random() * 30, // Рандомное смещение вправо
      }));
      setFlakes(newFlakes);
    };

    generateFlakes();
  }, []);

  return (
    <div className="snowfall">
      {flakes.map((flake) => (
        <div
          key={flake.id}
          className="snowflake"
          style={{
            left: `${flake.left}%`,
            animationDuration: `${flake.animationDuration}s`,
            animationDelay: `${flake.animationDelay}s`,
            fontSize: `${flake.fontSize}px`,
            transform: `rotate(${flake.rotate}deg) translateX(${flake.right}vw)`,
          }}
        >
          {flake.emoji}
        </div>
      ))}
    </div>
  );
};

export default Snowfall;
