import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

document.addEventListener('contextmenu', function(e) {
    if (e.target.tagName === 'IMG') {
        e.preventDefault();
    }
});

export default function ListCatalog() {
  const scrollContainerRef = useRef(null);
  const [expandedItem, setExpandedItem] = useState(null);
  const [showButtons, setShowButtons] = useState({ left: true, right: true });

  const handleScrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: -300, // Количество пикселей для прокрутки влево
        behavior: 'smooth', // Плавная прокрутка
      });
    }
  };

  const handleScrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: 300, // Количество пикселей для прокрутки вправо
        behavior: 'smooth', // Плавная прокрутка
      });
    }
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
    const startX = e.pageX - scrollContainerRef.current.offsetLeft;
    const scrollLeft = scrollContainerRef.current.scrollLeft;

    const handleMouseMove = (e) => {
      const x = e.pageX - scrollContainerRef.current.offsetLeft;
      const walk = (x - startX) * 1.2; // Scroll speed
      scrollContainerRef.current.scrollLeft = scrollLeft - walk;
    };

    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleItemClick = (index) => {
    setExpandedItem(index === expandedItem ? null : index);
  };

  const updateButtonVisibility = () => {
    if (scrollContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
      setShowButtons({
        left: scrollLeft > 0,
        right: scrollLeft < scrollWidth - clientWidth,
      });
    }
  };

  useEffect(() => {
    updateButtonVisibility();
    const currentContainer = scrollContainerRef.current;
    currentContainer.addEventListener('scroll', updateButtonVisibility);
    
    return () => {
      currentContainer.removeEventListener('scroll', updateButtonVisibility);
    };
  }, []);


  const navigate = useNavigate();

  const handleGenreClick = (genreId) => {
    navigate(`/genre/${genreId}`);
  };

  return (
    <div className='list_catalog'>
      <div className='list_catalog_header'>
        <span>Системные подборки</span>
      </div>
      <div className="left_button_2" onClick={handleScrollLeft} style={{ opacity: showButtons.left ? 1 : 0 }}>
        <svg width="40" height="36" viewBox="0 0 17 36" fill="none" xmlns="http://www.w3.org/2000/svg" data-tid="Arrow" style={{transform: 'rotate(0deg)'}}>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M5.5001 17.9998L16.6001 3.1998L13.4001 0.799805L0.500097 17.9998L13.4001 35.1998L16.6001 32.7998L5.5001 17.9998Z" fill="white"></path>
        </svg>
      </div>
      <div className="right_button_2" onClick={handleScrollRight} style={{ opacity: showButtons.right ? 1 : 0 }}>
        <svg width="40" height="36" viewBox="0 0 17 36" fill="none" xmlns="http://www.w3.org/2000/svg" data-tid="Arrow" style={{transform: 'rotate(180deg)'}}>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M5.5001 17.9998L16.6001 3.1998L13.4001 0.799805L0.500097 17.9998L13.4001 35.1998L16.6001 32.7998L5.5001 17.9998Z" fill="white"></path>
        </svg>
      </div>
      <div className='list_episode_selection' ref={scrollContainerRef} onMouseDown={handleMouseDown}>
          <a 
            href="/genre/4-Comedy" 
            className="selection_item" 
            id="4-Comedy"
          >
            <div className='item'>
              <img src='../media/genres/1.jpg' />
            </div>
          </a>
          <a
            href="/genre/22-Romance" 
            className="selection_item" 
            id="22-Romance"
          >
            <div className='item'>
              <img src='../media/genres/2.jpg' />
            </div>
          </a>
          <a
            href="/genre/2-Adventure" 
            className="selection_item" 
            id='2-Adventure'
          >
            <div className='item'>
              <img src='../media/genres/3.jpg' />
            </div>
          </a>
          <a
            href="/genre/37-Supernatural" 
            className="selection_item" 
            id='37-Supernatural'
          >
            <div className='item'>
              <img src='../media/genres/4.jpg' />
            </div>
          </a>
          <a
            href="/genre/8-Drama" 
            className="selection_item" 
            id='8-Drama'
          >
            <div className='item'>
              <img src='../media/genres/5.jpg' />
            </div>
          </a>
      </div>
    </div>
  );
}